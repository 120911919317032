<div class="hero-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="hero-banner-content" *ngFor="let Content of heroBannerContent;">
                    <h1 class="wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">{{Content.title}}</h1>
                    <p class="wow animate__animated animate__fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">{{Content.paragraphText}}</p>
                    <div class="btn-box">
                        <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn wow animate__animated animate__fadeInRight" data-wow-delay="200ms" data-wow-duration="1000ms"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="hero-banner-animation-image">
                    <img src="assets/img/main-banner/banner-three/img1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms" alt="image">
                    <img src="assets/img/main-banner/banner-three/img2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms" alt="image">
                    <img src="assets/img/main-banner/banner-three/img3.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms" alt="image">
                    <img src="assets/img/main-banner/banner-three/img4.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="500ms" data-wow-duration="1000ms" alt="image">
                    <img src="assets/img/main-banner/banner-three/img5.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="500ms" data-wow-duration="1000ms" alt="image">
                    <img src="assets/img/main-banner/banner-three/img6.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="1500ms" data-wow-duration="1000ms" alt="image">
                    <img src="assets/img/main-banner/banner-three/img7.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image">
                    <img src="assets/img/main-banner/banner-three/img8.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="500ms" data-wow-duration="1000ms" alt="image">
                    <img src="assets/img/main-banner/banner-three/img9.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="500ms" data-wow-duration="1000ms" alt="image">
                    <img src="assets/img/main-banner/banner-three/img10.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="500ms" data-wow-duration="1000ms" alt="image">
                    <img src="assets/img/main-banner/banner-three/main-pic.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="00ms" data-wow-duration="1000ms" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="vector-shape7 wow animate__animated animate__fadeInLeft"><img src="assets/img/shape/vector-shape7.png" alt="image"></div>
    <div class="vector-shape8 wow animate__animated animate__fadeInDown"><img src="assets/img/shape/vector-shape8.png" alt="image"></div>
</div>